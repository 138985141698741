import React, { useState, useEffect } from 'react';
import { Button } from 'zent';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkIsGoodSoldOut } from 'helper/goods';
import { GOODS_STATUS_SELLING } from 'const';
import { pushToHistory } from 'utils/web-tool';
import { ActivityStatus } from 'const/group-buying';
import { InviteDialog } from 'components/page/group-buying/invite-dialog';
import { Style } from './style';

export const GroupBuyingOrderButton = ({
  goodsDetail,
  customerId,
  groupBuyingDetail = {},
  onGroupBuying,
  currency,
  history,
  token,
  shareLinkUrl,
  handleBuriedPoint,
}) => {
  const { inviter, activityId, status, groupOrderId, isParticipate } = groupBuyingDetail;
  const intl = useIntl();
  const isSoldOut = checkIsGoodSoldOut(goodsDetail.variants) || goodsDetail?.goodsStatus === GOODS_STATUS_SELLING.DOWN;
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const isLogin = !!customerId;
  const onGoHome = () => {
    pushToHistory(history, `/`);
  };

  /** 如果当前登录且可邀请别人拼团且有库存才弹窗，则弹窗邀请弹窗哦 */
  useEffect(() => {
    if (status === ActivityStatus.GroupJoined && !isSoldOut) {
      setShowInviteDialog(true);
    }
  }, [status]);

  const onBuyAlone = () => {
    window.location.href = `${window.location.origin}/products/${goodsDetail?.handle}`;
  };

  const onViewOrder = () => {
    window.location.href = `${window.location.origin}/me/order/${token}`;
  };

  const onGroupBuy = () => {
    onGroupBuying({
      activityId,
      groupOrderId,
    });
  };
  //您已参团-邀请好友
  // 参加某某人的拼团
  // 团解散了显示去首页逛逛 + 发起拼团
  // 拼团已经成功了-显示去首页逛逛
  const onGroupBuyNew = () => {
    onGroupBuying({
      activityId,
    });
  };

  const goHomeText = intl.formatMessage({
    id: '9bfb4dbd2db747e3ac5cd1fc851c7cde',
    defaultMessage: '去首页逛逛',
  });

  return (
    <Style>
      {/* 可参团 */}
      {!isSoldOut && status === ActivityStatus.GroupWaiting && (
        <div className='goods-buy-button'>
          <Button type='primary' className='operate-button only-one-button' onClick={onGroupBuy}>
            {intl.formatMessage(
              { defaultMessage: `参加${inviter || 'XX'}的拼团`, id: '1426ee521c274e5ebdd0eaef6a728703' },
              {
                inviter: inviter || 'XX',
              },
            )}
          </Button>
        </div>
      )}

      {/* 已参团 */}
      {!isSoldOut && status === ActivityStatus.GroupJoined && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button only-one-button' onClick={onViewOrder}>
            {intl.formatMessage({ defaultMessage: '查看订单', id: 'e5adb3547d1241f0aa9770943cf858d2' })}
          </Button>
          <Button
            type='primary'
            className='theme-sub operate-button only-one-button'
            onClick={() => {
              setShowInviteDialog(true);
            }}
          >
            {intl.formatMessage({ defaultMessage: '邀请好友参团', id: '3631e83335614bcfb5a62d75f5d9c524' })}
          </Button>
        </div>
      )}

      {!isSoldOut && status === ActivityStatus.GroupEnd && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button' onClick={onGoHome}>
            {goHomeText}
          </Button>

          <Button type='primary' className='theme-sub goods-buy-button-2 operate-button' onClick={onGroupBuyNew}>
            {intl.formatMessage({
              id: '01722335147e4738a1ebc7f485c00fc5',
              defaultMessage: '发起拼团',
            })}
          </Button>
        </div>
      )}
      {!isParticipate && !isSoldOut && status === ActivityStatus.GroupSuccess && (
        <div className='goods-buy-button'>
          <Button type='primary' className='only-one-button operate-button' onClick={onGoHome}>
            {goHomeText}
          </Button>
        </div>
      )}
      {!!isParticipate && !isSoldOut && status === ActivityStatus.GroupSuccess && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button' onClick={onViewOrder}>
            {intl.formatMessage({ defaultMessage: '查看订单', id: 'e5adb3547d1241f0aa9770943cf858d2' })}
          </Button>
          <Button type='primary' className='theme-sub operate-button' onClick={onGoHome}>
            {goHomeText}
          </Button>
        </div>
      )}

      {/* 活动过期了 */}
      {!isSoldOut && status === ActivityStatus.ActivityEnd && (
        <div className='goods-buy-button'>
          <Button type='primary' className='only-one-button operate-button' onClick={onBuyAlone}>
            <FormattedMessage id='614e16cbb6744408af33ffb41ca17a66' defaultMessage='单独购买' />
          </Button>
        </div>
      )}

      {/* 商品售罄 */}
      {!!isSoldOut && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button' onClick={onBuyAlone}>
            <FormattedMessage id='614e16cbb6744408af33ffb41ca17a66' defaultMessage='单独购买' />
          </Button>

          <Button type='primary' className='theme-sub goods-buy-button-2 operate-button' disabled>
            <FormattedMessage id='goods.sold_out' defaultMessage='售罄' />
          </Button>
        </div>
      )}

      <InviteDialog
        showInviteDialog={showInviteDialog}
        onClose={() => {
          setShowInviteDialog(false);
        }}
        groupBuyingInfo={groupBuyingDetail}
        goodsId={goodsDetail?.goodsId}
        activityId={activityId}
        token={token}
        shareLinkUrl={shareLinkUrl}
        handleBuriedPoint={handleBuriedPoint}
      />
    </Style>
  );
};
