import styled from 'styled-components';

export const Style = styled.div``;

export const GroupStatusStyle = styled.div`
  padding: ${(props) => props.theme.getSpace([12, 16])} 0;
  margin-top: ${(props) => props.theme.getSpace([16, 24])};
  border-top: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  border-bottom: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  .status-desc {
    display: flex;
    align-items: center;
    text-align: right;
  }
  .rest {
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
  }
  .people-status {
    height: ${(props) => props.theme.getSpace([40, 40])};
    line-height: ${(props) => props.theme.getSpace([40, 40])};
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
  }
  .group-status-right {
    height: ${(props) => props.theme.getSpace([70, 80])};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  .time-value,
  .time-item {
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)} !important;
  }
  .group-card-profile {
    margin-top: ${(props) => props.theme.getSpace([12, 16])};
  }
  &.is-success {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;
