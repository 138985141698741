import React from 'react';
import cn from 'classnames';
import { intlLiToYuan } from 'utils/money';
import { UserProfile } from 'components/page/group-buying/profile-item';
import { ActivityStatus } from 'const/group-buying';
import { Icon } from 'components/base';
import { Style } from './style';
import { useIntl } from 'react-intl';

export const InviteNotify = ({ groupBuyingDetail = {}, goodsDetail = {}, currency }) => {
  const { status, participateNum, inviterImgUrl, inviter, differNum } = groupBuyingDetail;
  const intl = useIntl();

  const discountPrice = intlLiToYuan({ currency, value: goodsDetail?.compareAtPrice - goodsDetail?.minPrice });

  if (status === ActivityStatus.GroupJoined) return null;

  return (
    <Style>
      {status === ActivityStatus.GroupWaiting && (
        <>
          <UserProfile url={inviterImgUrl} size='mini' lastName={inviter} />
          <span className='invite-text'>
            {intl.formatMessage(
              {
                defaultMessage: '邀请好友拼团',
                id: 'ef6934789a284a21a53319a815802d3a',
              },
              {
                inviter,
                participateNum,
                discountPrice,
                differNum,
              },
            )}
          </span>
        </>
      )}
      {status === ActivityStatus.ActivityEnd && (
        <>
          <Icon name='iconic-jinggao' size='xSmall'></Icon>
          <span className='invite-text'>
            {intl.formatMessage({
              defaultMessage: '抱歉，该商品拼团活动已结束',
              id: '9864bad53ddd4e7fb824aa7ff89ca439',
            })}
          </span>
        </>
      )}

      {status === ActivityStatus.GroupSuccess && (
        <>
          <Icon name='iconic-chenggong' size='xSmall'></Icon>
          <span className='invite-text'>
            {intl.formatMessage({
              id: '384ebfb2fddf422a8920bf23bf259df7',
              defaultMessage: '拼团成功',
            })}
          </span>
        </>
      )}
      {status === ActivityStatus.GroupEnd && (
        <>
          <Icon name='iconic-jinggao' size='xSmall'></Icon>
          <span className='invite-text'>
            {intl.formatMessage({
              id: 'c2ab6f6047aa4ff8b3a4c1c57c15ab2f',
              defaultMessage: '人数不足，该拼团已解散',
            })}
          </span>
        </>
      )}
    </Style>
  );
};
