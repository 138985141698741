import styled from 'styled-components';

export const Style = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)};
  padding: ${(props) => props.theme.getSpace([16, 20])};
  ${(props) => props.theme.getBaseSize(props.theme.h9)};
  margin-bottom: ${(props) => props.theme.getSpace([10, 24])};
  .invite-text {
    margin-left: ${(props) => props.theme.getSpace([10, 12])};
  }
`;
