import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Button, Dialog } from 'zent';
import { useIntl } from 'react-intl';
import { UserProfileList } from 'components/page/group-buying/profile-list';
import { CountDown } from 'components/page/group-buying/count-down';
import { ActivityStatus } from 'const/group-buying';
import { Style, GroupStatusStyle } from './style';

export const GroupBuyingStatus = (props) => {
  const { groupBuyingDetail = {}, onTimeDone, refer } = props;
  const { participateCustomers = [], groupEndAt, differNum, status } = groupBuyingDetail;
  const [peoples, setPeople] = useState([]);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const showGroupPeople = !!peoples?.length; // 拼团成功或者待拼团展示
  const intl = useIntl();

  const getDiffPeople = () => {
    const list = [];
    for (let i = 0; i < differNum; i++) {
      list.push({
        type: 'normal',
      });
    }
    return list;
  };

  useEffect(() => {
    const groupPeoples =
      participateCustomers.map((item) => {
        return {
          url: item?.imgUrl,
          lastName: item?.name,
          flagText: item?.isHeader
            ? intl.formatMessage({
                id: '10defe0be4434692bd5970f468dff09a',
                defaultMessage: '团长',
              })
            : '',
        };
      }) || [];
    const diffPeople = getDiffPeople();

    setPeople([...groupPeoples, ...diffPeople]);
  }, [groupBuyingDetail]);

  useEffect(() => {
    // 页面链接为分享链接才弹这个窗
    if (
      refer === 'share' &&
      (status === ActivityStatus.ActivityEnd ||
        status === ActivityStatus.GroupEnd ||
        status === ActivityStatus.GroupSuccess)
    ) {
      setShowInviteDialog(true);
    }
  }, [status]);

  const requireCount = participateCustomers?.length + differNum;
  const success = status === ActivityStatus.GroupSuccess;

  const DialogConfig = {
    [ActivityStatus.GroupSuccess]: {
      title: intl.formatMessage({
        id: 'f6285bc9928449f0a2aa36bfc34b3103',
        defaultMessage: '你来晚了, 拼团已完成',
      }),
      content: intl.formatMessage({
        id: '0b5a983579bb4ee8966fbebd1f812f8a',
        defaultMessage: '该条拼团活动已完成, 你可重新发起拼团或参与其他团。',
      }),
    },

    [ActivityStatus.GroupEnd]: {
      title: intl.formatMessage({
        id: 'c2ab6f6047aa4ff8b3a4c1c57c15ab2f',
        defaultMessage: '人数不足，该拼团已解散',
      }),
      content: (
        <div>
          <p>
            {intl.formatMessage({
              id: 'd10c263a4e8842a0946fc43b698613f0',
              defaultMessage: '人数不足导致拼单已解散。',
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'c2bf68e9f2d94a7c8614d05f42ddcabd',
              defaultMessage: '你可以重新发起拼单，并邀请你的好友一起参团，分享越多拼单成功率越高。',
            })}
          </p>
        </div>
      ),
    },
    [ActivityStatus.ActivityEnd]: {
      title: intl.formatMessage({
        id: 'a34b765bf9434fb093db764038321ad1',
        defaultMessage: '抱歉，拼单已解散',
      }),
      content: (
        <div>
          <p>
            {' '}
            {intl.formatMessage({
              id: 'defc9d54fb2d471e8553b7c1b6010924',
              defaultMessage: '该商品拼团活动已结束。',
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: '99a3dd8ddbd3465f9c2daab6d0f0f624',
              defaultMessage: '你可继续参加其它商品的拼团活动，并邀请你的好友一起参团。',
            })}
          </p>
        </div>
      ),
    },
  };

  // 拼团结束或者解散不展示
  if (!status) return null;

  return (
    <Style>
      {status !== ActivityStatus.ActivityEnd && status !== ActivityStatus.GroupEnd && (
        <GroupStatusStyle className={cn('group-status', { 'is-success': success })}>
          <div className='group-status-left'>
            <div>
              {success
                ? intl.formatMessage({
                    id: '384ebfb2fddf422a8920bf23bf259df7',
                    defaultMessage: '拼团成功',
                  })
                : intl.formatMessage({
                    id: '022c913d6bcb40a4ae7d57ecb3855db1',
                    defaultMessage: '拼团进度',
                  })}
            </div>
            {showGroupPeople && <UserProfileList className='group-card-profile' size='normal' list={peoples} />}
          </div>
          {!success && (
            <div className='group-status-right'>
              <div className='status-desc'>
                <span className='rest'>
                  {intl.formatMessage({
                    id: 'ecb122a3e41a47ae892ecceab3dc7d89',
                    defaultMessage: '时间剩余',
                  })}
                </span>{' '}
                &nbsp;
                <CountDown className='group-time' timeEndAt={groupEndAt} styleType='normal' onTimeDone={onTimeDone} />
              </div>
              <div className='people-status'>
                {participateCustomers?.length} / {requireCount},{' '}
                {intl.formatMessage(
                  {
                    id: 'dac5fab7310745b8b45519c60963814e',
                    defaultMessage: '仅差{differNum}人',
                  },
                  {
                    differNum,
                  },
                )}{' '}
              </div>
            </div>
          )}
        </GroupStatusStyle>
      )}

      <Dialog
        visible={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        title={DialogConfig?.[status]?.title}
      >
        <div>{DialogConfig?.[status]?.content}</div>
        <Button
          style={{
            marginTop: '50px',
            float: 'right',
          }}
          type='primary'
          onClick={() => {
            setShowInviteDialog(false);
          }}
        >
          {intl.formatMessage({
            id: 'b78d1494235c43edba34f5b8af98338d',
            defaultMessage: '知道了',
          })}
        </Button>
      </Dialog>
    </Style>
  );
};
