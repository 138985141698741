import { useEffect, useState } from 'react';
import groupBuyingApi from 'api/group-buying';
import { filterEmptyObj } from 'utils';

/** 获取分享短链  */
export const useShareLink = ({ kdtId, goodsId, activityId, token }) => {
  const [shareLinkUrl, setShareLinkUrl] = useState('');
  useEffect(() => {
    if (!kdtId || !goodsId || !activityId || !token) return;

    groupBuyingApi
      .getGroupDetailShareLink(
        filterEmptyObj({
          kdtId,
          goodsId,
          activityId,
          orderToken: token,
        }),
      )
      .then((res) => {
        setShareLinkUrl(res?.url);
      });
  }, [kdtId, goodsId, activityId, token]);

  return {
    shareLinkUrl,
  };
};
