import styled from 'styled-components';

export const Style = styled.div`
  .goods-buy-button {
    display: flex;
  }
  .only-one-button {
    width: 100%;
  }
  .operate-button {
    height: ${(props) => props.theme.getSpace([40, 56])} !important;
    ${(props) => props.theme.getBaseSize(props.theme.h10)};
  }
`;
